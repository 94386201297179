import React from 'react';
import {CARD_PRODUCTS} from '../../types/card';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import i18n from '../../translations/i18n';
import styles from '../dashboard/dashboard.module.scss';
import {Selector} from '../../components/selector/Selector';
import CustomButton from '../../components/button/CustomButton';
import {ReactComponent as FilterIcon} from '../../assets/icons/filter.svg';
import {Checkbox} from '@mui/joy';

export const getColumns = (
    handleButtonClick: (uuid: string) => void,
    cardProducts: CARD_PRODUCTS[],
    onProductSelect: (cardId: number, product: string) => void,
    handleWatermarkToggle: (cardId: number, newValue: boolean) => void
): GridColDef[] => [
    { field: 'id', headerName: 'Card ID', type: 'number', width: 70 },
    { field: 'profileId', headerName: 'Profile ID', type: 'number', width: 70 },
    { field: 'orderId', headerName: 'Order ID', type: 'number', width: 70 },
    { field: 'uuid', headerName: 'UUID', type: 'string', width: 130 },
    {
        field: 'product',
        headerName: 'Product',
        type: 'string',
        width: 130,
        renderCell: (params: GridRenderCellParams) => {
            if (params.row.product) {
                return params.row.product;
            } else {
                return (
                    <Selector
                        label={`${i18n.t('labels.choose')} ...`}
                        items={cardProducts}
                        onSelect={(value: string) => onProductSelect(params.row.id, value)}
                        icon={FilterIcon}
                        formStyle={styles.rowSelector}
                        notConvert
                    />
                );
            }
        },
    },
    {
        field: 'qrCode',
        headerName: 'QR Code',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
            <CustomButton
                text= {`${i18n.t('common.download')}`}
                onClick={() => handleButtonClick(params.row.uuid)}
                sx={styles.btn}
            />
        ),
    },
    { field: 'cardHolder', headerName: 'Card holder', type: 'string', width: 200 },
    { field: 'cardBuyer', headerName: 'Card buyer', type: 'string', width: 200 },
    {
        field: 'watermark',
        headerName: 'Watermark',
        width: 100,
        renderCell: (params: GridRenderCellParams) => (
            <Checkbox
                checked={params.row.watermark}
                onChange={(event) =>
                    handleWatermarkToggle(params.row.profileId, event.target.checked)
                }
            />
        ),
    },
    { field: 'status', headerName: 'Status', type: 'string', width: 120 },
    { field: 'created', headerName: 'Created', type: 'string', width: 160 },
];