import React from 'react';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import i18n from '../../translations/i18n';
import {QRCodeFilterSizes} from './Dashboard';
import {eyeSvgCode} from '../../constants/eyeSvgCode';
import {InputWithSubmit} from '../../components/input/InputWithSubmit';

export const getColumns = (
    onUuidSelect: (uuid: string, paymentId: number) => void,
    loadingSaveUuid: boolean
): GridColDef[] => [
    { field: 'id', headerName: 'Payment ID', type: 'number', width: 100 },
    { field: 'orderId', headerName: 'Order ID', type: 'number', width: 80 },
    { field: 'userEmail', headerName: 'User', type: 'string', width: 200 },
    {
        field: 'uuid',
        headerName: 'UUID',
        type: 'string',
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
            if (params.row.uuid) {
                return params.row.uuid;
            } else {
                return (
                    <InputWithSubmit
                        placeholder={i18n.t('labels.enterUuid')}
                        onSave={(value: string) => onUuidSelect(value, params.row.id)}
                        loading={loadingSaveUuid}
                    />
                );
            }
        },
    },
    { field: 'cardProduct', headerName: 'Product', type: 'string', width: 130 },
    { field: 'color', headerName: 'Color', type: 'string', width: 90 },
    { field: 'logoUrl', headerName: 'Logo', type: 'string', width: 300 },
    { field: 'personalized', headerName: 'Personalized', type: 'boolean', width: 110 },
    { field: 'orderType', headerName: 'Order Type', type: 'string', width: 110 },
    { field: 'created', headerName: 'Created', type: 'string', width: 160 }

];

export const getQrCodeEyeSize = (qrcodeSize: QRCodeFilterSizes): string => {
    if (qrcodeSize === 300) return '63';
    if (qrcodeSize === 600) return '126';
    if (qrcodeSize === 1200) return '252';
    return '126';
};

export const getQrCodeEyeSizeCordinates = (qrcodeSize: QRCodeFilterSizes): {x: string, y: string} => {
    if (qrcodeSize === 300) return {x: '235', y: '1'};
    if (qrcodeSize === 600) return {x: '471', y: '3'};
    if (qrcodeSize === 1200) return {x: '942', y: '6'};
    return {x: '471', y: '3'};
};

export const replacePlaceholderImageWithLogo = (
    svgElement: SVGSVGElement,
    logoSvgCode: string
) => {
    const imageElement = svgElement.querySelector('image');
    if (imageElement) {
        const x = imageElement.getAttribute('x') || '0';
        const y = imageElement.getAttribute('y') || '0';
        const width = imageElement.getAttribute('width') || '0';
        const height = imageElement.getAttribute('height') || '0';

        const parser = new DOMParser();
        const logoDoc = parser.parseFromString(logoSvgCode, 'image/svg+xml');
        const logoElement = logoDoc.documentElement as unknown as SVGSVGElement;

        logoElement.setAttribute('x', x);
        logoElement.setAttribute('y', y);
        logoElement.setAttribute('width', width);
        logoElement.setAttribute('height', height);

        imageElement.parentNode?.replaceChild(logoElement, imageElement);
    }
};

export const getQrCodeEyeElement = (
    eyeSvgCode: string,
    eyeSize: string,
    xCoordinate: string,
    yCoordinate: string
): SVGSVGElement => {
    const parser = new DOMParser();
    const eyeSvgDoc = parser.parseFromString(eyeSvgCode, 'image/svg+xml');
    const eyeElement = eyeSvgDoc.documentElement as unknown as SVGSVGElement;

    eyeElement.setAttribute('x', xCoordinate);
    eyeElement.setAttribute('y', yCoordinate);
    eyeElement.setAttribute('width', eyeSize);
    eyeElement.setAttribute('height', eyeSize);

    return eyeElement;
};

export const replaceEyesWithSVGs = (svg: SVGSVGElement, qrCodeSize: QRCodeFilterSizes) => {
    const rect00 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-0-0\')"]');
    const rect01 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-0-1\')"]');
    const rect02 = svg.querySelector('rect[clip-path="url(\'#clip-path-corners-square-color-1-0\')"]');
    const eyeSize = getQrCodeEyeSize(qrCodeSize);
    const eyeCordinates = getQrCodeEyeSizeCordinates(qrCodeSize);
    const eyeImage = eyeSvgCode;

    if (rect00) {
        const customImage00 = getQrCodeEyeElement(eyeImage, eyeSize, eyeCordinates.y, eyeCordinates.y);
        rect00.parentNode?.replaceChild(customImage00, rect00);
    }

    if (rect01) {
        const customImage01 = getQrCodeEyeElement(eyeImage, eyeSize, eyeCordinates.y, eyeCordinates.x);
        rect01.parentNode?.replaceChild(customImage01, rect01);
    }

    if (rect02) {
        const customImage02 = getQrCodeEyeElement(eyeImage, eyeSize, eyeCordinates.x, eyeCordinates.y);
        rect02.parentNode?.replaceChild(customImage02, rect02);
    }
};