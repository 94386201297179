import { createSlice } from '@reduxjs/toolkit';
import {KeycloakUser, User} from '../../types/user';
import {KeycloakConverter} from '../../converters/KeycloakConverter';
import {decode} from '../../utils/jwt';

interface UserSessionSlice {
    token: string;
    user: User | null;
    kkUser: KeycloakUser | null;
}

const initialState: UserSessionSlice = {
    token: '',
    user: null,
    kkUser: null
};

const userSessionSlice = createSlice({
    name: 'userSession',
    initialState,
    reducers: {
        storeToken: (state, action) => {
            const token = action.payload;

            if (token) {
                const decodedToken = decode(token);
                state.token = token;
                state.kkUser = KeycloakConverter.convertFromKkTokenToKeycloakUser(decodedToken);
            }
        },
        storeUser: (state, action) => {
            const user: User = action.payload;

            if (user) {
                state.user = user;
            }

        },
    },
});

export default userSessionSlice.reducer;

export const { storeToken, storeUser  } = userSessionSlice.actions;

export const selectToken = (state: any) => state.userSession.token;
export const selectUser = (state: any) => state.userSession.user;
export const selectKkUser = (state: any) => state.userSession.kkUser;

