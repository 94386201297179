import React, { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import useLoginController from './useLoginController';
import {Protected} from './Protected';
import {routes} from '../constants/routes';
import AllCards from '../pages/allCards/AllCards';

const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));

const PageRouter: FC = () => {
    const router = createBrowserRouter([
        {
            path: routes.dashboard,
            element: <Protected component={<Dashboard />}></Protected>,
        },
        {
            path: routes.allCards,
            element: <Protected component={<AllCards />}></Protected>,
        }
    ]);

    const { initialized } = useLoginController();

    if (!initialized) {
        return <div>Loading...</div>;
    }

    return <RouterProvider router={router} />;
};

export default PageRouter;