import React, { Suspense } from 'react';
import {initOptions, keycloakClient} from './keycloak/Keycloak';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import PageRouter from './routes/PageRouter';
import store from './store/store';
import {Provider} from 'react-redux';
import {AlertProvider} from "./context/AlertProvider";

function App() {
    return (
        <Provider store={store}>
            <ReactKeycloakProvider
                authClient={keycloakClient}
                initOptions={initOptions}
            >
                <AlertProvider>
                    <Suspense fallback={<div></div>}>
                        <PageRouter />
                    </Suspense>
                </AlertProvider>
            </ReactKeycloakProvider>
        </Provider>
    );
}

export default App;