import React, {useRef, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {GridPaginationModel} from '@mui/x-data-grid/models/gridPaginationProps';
import {SearchInput} from '../../components/searchInput/SearchInput';
import {API_CALL_DEBOUNCE_TIME_IN_MS} from '../../constants/common';
import {useDebouncedCallback} from 'use-debounce';
import i18n from '../../translations/i18n';
import styles from '../dashboard/dashboard.module.scss';
import {ReactComponent as FilterIcon} from '../../assets/icons/filter.svg';
import {Selector} from '../../components/selector/Selector';
import {downloadFile} from '../../utils/download';
import {getQrCodePrettier} from '../../components/qrCode/QrCodePrettier';
import {CARD_PRODUCTS} from '../../types/card';
import {WarningModal} from '../../components/warningModal/WarningModal';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import {assignCardProductApi} from '../../api/card';
import {CardConverter} from '../../converters/CardConverter';
import HomeHeader from '../../components/homeHeader/HomeHeader';
import useAllCardsController from './useAllCardsController';
import {getColumns} from './allCardsColumnsUtils';
import {replaceEyesWithSVGs, replacePlaceholderImageWithLogo} from '../dashboard/dashboardUtils';
import {logoSvgCode} from '../../constants/logoSvgCode';
import {updateCardProfileWatermarks} from '../../api/cardProfile';
import {isEmpty} from '../../utils/common';
import {WatermarkModal} from '../../components/watermarkModal/WatermarkModal';
import CustomButton from '../../components/button/CustomButton';

export type QRCodeFilterSizes = 300 | 600 | 1200;
const qrCodeSizeItems: string[] = ['300', '600', '1200'];

interface Modal {
    data: string;
    cardId: number;
}

const AllCards = () => {
    const {
        getCardsApiCall, cardPagination, setApiParams, apiParams,
        loading, setCardPagination, cardProducts
    } = useAllCardsController();
    const [modalOpen, setModalOpen] = useState<Modal | undefined>(undefined);
    const [qrCodeSize, setQrCodeSize] = useState<QRCodeFilterSizes>(600);
    const canvasRef = useRef<HTMLDivElement>(null);
    const [showWatermarkModal, setShowWatermarkModal] = useState<boolean>(false);

    const getQrCodeData = (uuid: string) => (process.env.REACT_APP_WEBSHOP_URL ?? 'https://icards.link/') + uuid;

    const downloadSVG = (svgElement: Element, uuid: string) => {
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        downloadFile(url, `${uuid}.svg`);
    };

    const onProductSelect = (cardId: number, product: string) => {
        setModalOpen({ data: product, cardId: cardId});
    };


    const downloadQrCodePrettier = async (uuid: string, download: boolean = true) => {
        const qrCodeImage = getQrCodePrettier(getQrCodeData(uuid), qrCodeSize);

        if (!canvasRef.current) return;

        //qrCodeImage.append(canvasRef.current);
        return await new Promise<SVGSVGElement | undefined>((resolve, reject) => {
            qrCodeImage.getRawData('svg')
                .then(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(reader.result as string, 'image/svg+xml');
                        const svgElement = svgDoc.querySelector('svg');

                        if (svgElement) {
                            replacePlaceholderImageWithLogo(svgElement, logoSvgCode);
                            replaceEyesWithSVGs(svgElement, qrCodeSize);
                            if (canvasRef.current) {
                                canvasRef.current.innerHTML = '';
                                //canvasRef.current.appendChild(svgElement);
                                download && downloadSVG(svgElement, uuid);
                                resolve(svgElement);
                            }
                        }
                    };
                    blob && reader.readAsText(blob);
                }).catch(error => {
                    reject(error);
                });
        });
    };

    const handleWatermarkToggle = (cardProfileId: number, enable: boolean) => {
        updateCardProfileWatermarks(cardProfileId, enable)
            .then((res) => {
                if (isEmpty(res.error)) {
                    getCardsApiCall();
                }
            });
    };

    const columns = getColumns(downloadQrCodePrettier, cardProducts, onProductSelect, handleWatermarkToggle);

    const setPaginationModel = (pagModel: GridPaginationModel) => (
        setApiParams(prevState => ({...prevState, page: pagModel.page, size: pagModel.pageSize}))
    );

    const onSearch = (text: string) => {
        setApiParams(prevState => ({
            ...prevState,
            searchByEmailOrOrderId: encodeURIComponent(text)
        }));
    };

    const onSelect = (option: string) => {
        const optionNum = Number(option);
        setQrCodeSize(optionNum as QRCodeFilterSizes);
    };

    const onSearchDebounced = useDebouncedCallback((text: string) => {
        return onSearch(text);
    }, API_CALL_DEBOUNCE_TIME_IN_MS);

    const handleOnConfirmModal = () => {
        if (!modalOpen) return;

        assignCardProductApi(modalOpen.cardId, modalOpen.data as CARD_PRODUCTS)
            .then((res) => {
                if (res.error) return;
                setCardPagination(prevState => prevState &&
                    { ...prevState, cards: prevState?.cards.map(card => (
                        card.id === res.data.id
                            ? CardConverter.convertFromCardApiToCard(res.data)
                            : card))
                    });

            })
            .finally(() => setModalOpen(undefined));
    };

    const handleCloseDeleteModal = () => setModalOpen(undefined);

    if (!cardPagination?.cards) return <></>;

    return (
        <div style={{height: 800, margin: '16px'}}>
            <HomeHeader />
            <div className={styles.searchContainer}>
                <CustomButton
                    text={i18n.t('common.updateWatermark')}
                    onClick={() => setShowWatermarkModal(true)}
                    sx={styles.btnWatermark}
                />
                <SearchInput
                    placeholder={`${i18n.t('labels.searchByOrderIdOrCardHolderBuyer')}`}
                    onSearch={onSearchDebounced}
                />
                <Selector
                    label={i18n.t('labels.qrCodeSize')}
                    items={qrCodeSizeItems}
                    onSelect={onSelect as any}
                    icon={FilterIcon}
                    defaultValue={'600'}
                />
            </div>
            <div ref={canvasRef}></div>
            <DataGrid
                rows={cardPagination?.cards}
                rowCount={cardPagination.totalElements}
                columns={columns}
                loading={loading}
                paginationModel={{page: apiParams.page, pageSize: apiParams.size}}
                pageSizeOptions={[25]}
                paginationMode={'server'}
                onPaginationModelChange={setPaginationModel}
            />
            <WarningModal
                isOpen={modalOpen != null}
                icon={<WarningIcon/>}
                title={i18n.t('modal.areYouSure')}
                text={i18n.t('modal.assignProductText', {product: modalOpen?.data, cardId: modalOpen?.cardId})}
                confirmBtnText={i18n.t('common.save')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={handleOnConfirmModal}
                onCancel={handleCloseDeleteModal}
            />
            {showWatermarkModal &&
                <WatermarkModal
                    onClose={() => setShowWatermarkModal(false)}
                    onActionComplete={getCardsApiCall}
                />
            }
        </div>
    );
};

export default AllCards;