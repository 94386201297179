import {useEffect} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {useAppDispatch, useAppSelector} from '../store/appDispatch';
import {getUserInfoByKeycloakUserId} from '../api/user';
import {UserConverter} from '../converters/api/UserConverter';
import {User} from '../types/user';
import {
    selectKkUser,
    selectToken,
    selectUser,
    storeToken,
    storeUser,
} from '../store/slices/userSessionSlice';

const useLoginController = () => {
    const { initialized, keycloak } = useKeycloak();
    const dispatch = useAppDispatch();
    const tokenFromStore = useAppSelector(selectToken);
    const user = useAppSelector(selectUser);
    const kkUser = useAppSelector(selectKkUser);

    useEffect(() => {
        if (!initialized) return;

        const token = keycloak.token;
        token && dispatch(storeToken(token));
    }, [keycloak, initialized]);

    useEffect(() => {
        if (!user && tokenFromStore && kkUser) {
            getUserInfoByKeycloakUserId(kkUser.id)
                .then(res => {
                    if (res.error) return;

                    const user: User | null = UserConverter.convertFromUserApiToUser(res.data);
                    dispatch(storeUser(user));
                });
        }
    }, [tokenFromStore]);

    return {
        initialized
    };
};

export default useLoginController;