import {User} from '../../types/user';

export const UserConverter = {
    convertFromUserApiToUser(data: any): User | null {
        if (!data) return null;

        return {
            id: data.id,
            email: data.userEmail,
            language: data.language,
            fullname: data.fullname,
            accountType: data.accountType
        };
    }
};