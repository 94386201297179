import React, {FC, useState} from 'react';
import ModalContainer from '../modalContainer/ModalContainer';
import {Input} from '../input/Input';
import i18n from '../../translations/i18n';
import styles from './watermark-modal.module.scss';
import CustomButton from '../button/CustomButton';
import {CircularProgress} from '@mui/material';
import {isBlank} from '../../utils/common';
import {updateWatermarks} from '../../api/cardProfile';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import {useAlert} from '../../context/AlertProvider';
import {ERROR, SUCCESS} from '../../constants/common';

interface WatermarkModalProps {
    onClose: () => void;
    onActionComplete: () => void;
}

export const WatermarkModal: FC<WatermarkModalProps> = ({
    onClose, onActionComplete
}) => {
    const [userEmail, setUserEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const {setAlert} = useAlert();

    const isSaveDisabled = isBlank(userEmail);

    const onEmailInputChange = (value: string) => setUserEmail(value);

    const handleUpdateWatermark = (enable: boolean) => {
        if (isSaveDisabled) return;

        setLoading(true);
        updateWatermarks(userEmail, enable)
            .then(res => {
                if (res.error) {
                    const errorMsg = res.error.response?.data?.error;
                    setAlert(ERROR, errorMsg);
                } else {
                    setAlert(SUCCESS, i18n.t('message.watermarkChanges'));
                    onActionComplete();
                    onClose();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <ModalContainer>
            <div className={styles.header}>
                <div className={styles.headerTxt}>
                    {i18n.t('desc.updateWatermark')}
                </div>
                <div className={styles.icon} onClick={onClose}>
                    <CloseIcon stroke='#808080'/>
                </div>
            </div>
            <Input
                label={i18n.t('common.emailAddress')}
                onChange={onEmailInputChange}
                required={true}
            />
            <div className={styles.btnContainer}>
                {loading ?
                    <CircularProgress/>
                    : <>
                        <CustomButton
                            onClick={() => handleUpdateWatermark(true)}
                            text={i18n.t('common.enable')}
                            disabled={isSaveDisabled}
                            sx={styles.btn}
                        />
                        <CustomButton
                            onClick={() => handleUpdateWatermark(false)}
                            text={i18n.t('common.disable')}
                            disabled={isSaveDisabled}
                            sx={styles.btn}
                        />
                    </>
                }
            </div>
        </ModalContainer>
    );

};