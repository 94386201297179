import {configDefaultWithToken, postApiCall} from './base';


const cardProfileUrl = '/api/card-profile';
const watermarkUrl = `${cardProfileUrl}/watermark`;
const watermarkByCardProfileIdUrl = (id: number) => `${cardProfileUrl}/${id}/watermark`;

export const updateWatermarks = async (userEmail: string, watermark: boolean) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            userEmail,
            enabled: watermark
        }
    };

    return await postApiCall(watermarkUrl, {}, config);
};

export const updateCardProfileWatermarks = async (cardProfileId: number, watermark: boolean) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            enabled: watermark
        }
    };

    return await postApiCall(watermarkByCardProfileIdUrl(cardProfileId), {}, config);
};