import {FC} from 'react';
import styles from './home-header.module.scss';
import {  NavLink } from 'react-router-dom';
import i18n from '../../translations/i18n';
import { routes } from '../../constants/routes';

const HomeHeader: FC = () => {

    return (
        <>
            <nav className={styles.navContainer}>
                <div className={styles.navLinks}>
                    <NavLink to={routes.dashboard} className={({isActive})=> isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.newOrders')}
                    </NavLink>
                    <NavLink to={routes.allCards} className={({isActive})=> isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.allCards')}
                    </NavLink>
                </div>
            </nav>
        </>
    );
};

export default HomeHeader;