// alerts
export const SUCCESS = 'success';
export const ERROR = 'error';

// timeouts
export const KEYCLOAK_TOKEN_SESSION_TIMEOUT = 6000;
export const ALERT_TIMEOUT = 6000;

export const ICARDS_FULL_URL = process.env.REACT_APP_WEBSHOP_URL;

export const CARD_API_PAGE_SIZE = 25;

export const API_CALL_DEBOUNCE_TIME_IN_MS = 500;