import {configDefaultWithToken, getApiCall} from './base';

const userUrl = '/api/user';
const userKeycloakUrl = `${userUrl}/keycloak`;

export const getUserInfoByKeycloakUserId = async (keycloakUserId: string) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${userKeycloakUrl}/${keycloakUserId}`, config);
};