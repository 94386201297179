import React, { FC, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import styles from './app-header.module.scss';
import { ReactComponent as IcardsLogoIcon } from '../../../assets/icons/logos/icardLogo.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatar.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow/arrow.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as VisitIcon } from '../../../assets/icons/visit.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import i18n from '../../../translations/i18n';
import { routes } from '../../../constants/routes';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {openInNewTab} from '../../../utils/common';
import {ICARDS_FULL_URL} from '../../../constants/common';

const AppHeader: FC = () => {
    const { keycloak } = useKeycloak();
    const user = useAppSelector(selectUser);

    const onLogout = () => keycloak.logout();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const TypographyStyle = {
        p: 1.5,
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
    };

    const navigateToWebsite = () => openInNewTab(ICARDS_FULL_URL);

    return (
        <>
            <nav className={styles.navContainer}>
                <a href={routes.dashboard} className={styles.logo}>
                    <IcardsLogoIcon />
                </a>
                <div className={styles.userInfo}>
                    <div className={styles.userInfo} aria-haspopup="true" aria-controls={id} onClick={handleClick} role="button" >
                        <div className={styles.avatar}>
                            <AvatarIcon/>
                        </div>
                        <div className={styles.username}>
                            {user?.fullname}
                        </div>
                        <div className={styles.arrow}>
                            <ArrowIcon />
                        </div>
                    </div>     
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography className={`${styles.settingWrapperSignIn} ${styles.settingWrapper}`} sx={TypographyStyle}>
                            <div className={styles.subtext}>
                                {i18n.t('header.signedInAs')}
                            </div>
                            <div className={styles.text}>
                                {user?.email}
                            </div>
                        </Typography>
                        <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle} onClick={navigateToWebsite}>
                            <div className={styles.iconWrapper}><VisitIcon /></div>
                            {i18n.t('header.visitWebsite')}
                        </Typography>
                        <div className={styles.logoutButton} onClick={onLogout}>
                            <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle}>
                                <LogoutIcon />
                                {i18n.t('header.signOut')}
                            </Typography>
                        </div>
                    </Popover>
                </div>
            </nav>
        </>
    );
};

export default AppHeader;