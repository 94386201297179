import { ReactNode, FC } from 'react';
import AppHeader from '../AppHeader/AppHeader';
import styles from './main-shell.module.scss';


interface MainShellProps {
    children: ReactNode;
    hasStickyFooter?: boolean;
}

const MainShell: FC<MainShellProps> = ({
    children
}) => {

    return (
        <div className={styles.pageBox}>
            <AppHeader />
            <div className={styles.mainBox}>{children}</div>
            {/*<AppFooter hasStickyFooter={hasStickyFooter}/>*/}
        </div>
    );
};

export default MainShell;