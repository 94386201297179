import React, {FC, useState} from 'react';
import styles from './input.module.scss';
import CustomButton from '../button/CustomButton';
import i18n from '../../translations/i18n';

interface InputWithSubmitProps {
    id?: string;
    placeholder: string;
    onSave: (uuid: string) => void;
    disabled?: boolean;
    loading: boolean;
}

export const InputWithSubmit: FC<InputWithSubmitProps> = ({
    id, placeholder, onSave, disabled, loading
}) => {
    const [value, setValue] = useState<string>('');

    const isSaveDisabled = value.length != 7;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);
    const handleOnSave = () => onSave(value);

    return (
        <div className={styles.containerWithSubmit}>
            <input
                id={id}
                className={styles.input}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
            />
            <CustomButton
                text={i18n.t('common.save')}
                loading={loading}
                onClick={handleOnSave}
                disabled={isSaveDisabled}
                sx={styles.btn}
            />
        </div>
    );
};