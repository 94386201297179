import {configDefaultWithToken, getApiCall, postApiCall} from './base';
import {CARD_PRODUCTS, CardApiParams} from '../types/card';

const cardUrl = '/api/card';
const cardProductsUrl = `${cardUrl}/products`;

const cardGenerateUrl = `${cardUrl}/admin-generate`;
const cardProductUrl = (id: number) => `${cardUrl}/product/${id}`;
const cardUuidAssignUrl = (uuid: string) => `${cardUrl}/uuid/assign/${uuid}`;

export const getCardsApi = async (apiParams?: CardApiParams) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            page: apiParams?.page,
            size: apiParams?.size,
            searchByEmailOrOrderId: apiParams?.searchByEmailOrOrderId
        }
    };

    return await getApiCall(cardUrl, config);
};

export const generateCardsApi = async (noofCards: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            noofCards: noofCards
        }
    };

    return await postApiCall(cardGenerateUrl, {}, config);
};

export const getCardProductsApi = async () => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(cardProductsUrl, config);
};

export const assignCardUuidApi = async (paymentId: number, uuid: string) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            paymentId: paymentId
        }
    };

    return await postApiCall(cardUuidAssignUrl(uuid), {}, config);
};

export const assignCardProductApi = async (cardId: number, productType: CARD_PRODUCTS) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            productType: productType
        }
    };

    return await postApiCall(cardProductUrl(cardId), {}, config);
};
