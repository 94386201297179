import {FC, ReactElement, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {useAppDispatch, useAppSelector} from '../store/appDispatch';
import {selectUser, storeToken} from '../store/slices/userSessionSlice';
import {KEYCLOAK_TOKEN_SESSION_TIMEOUT} from '../constants/common';
import MainShell from '../components/layout/MainShell/MainShell';

interface ProtectedProps {
    redirectTo?: string,
    component: ReactElement<any>
}

export const Protected: FC<ProtectedProps> = ({
    redirectTo = '/',
    component
}) => {
    const dispatch = useAppDispatch();
    const { keycloak } = useKeycloak();
    const user = useAppSelector(selectUser);

    const loggedIn = keycloak.authenticated;

    if (keycloak) {
        keycloak.onTokenExpired = () => {
            keycloak.updateToken(KEYCLOAK_TOKEN_SESSION_TIMEOUT)
                .then(() => {
                    const token = keycloak.token;
                    token && dispatch(storeToken(token));
                });
        };
    }

    useEffect(() => {
        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { return; };
        };
    }, [keycloak]);

    if (!loggedIn) {
        keycloak.login();
        return <></>;
    }

    if (user == null) {
        return <></>;
    }

    if (!component) {
        return <Navigate to={redirectTo} replace />;
    }

    return (
        <MainShell>
            {component}
        </MainShell>
    );
};