import React, {FC, useEffect, useState} from 'react';
import styles from './custom-button.module.scss';
import CircularProgress from '@mui/joy/CircularProgress';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import i18n from '../../translations/i18n';

interface CustomButtonProps {
    text: string,
    onClick: () => void,
    loading?: boolean,
    sx?: any,
    disabled?: boolean
}

const CustomButton: FC<CustomButtonProps> = ({
    text, onClick, loading, sx, disabled
}) => {

    const [iconExist, setIconExist] = useState(false);

    useEffect(()=>{
        setIconExist(text === i18n.t('common.changesSaved')); 
    },[text]);

    if (loading) {
        return (
            <div className={`${sx} ${styles.container}`}>
                <div className={styles.btn}><CircularProgress color="neutral" size="sm" variant="soft"/></div>
            </div>
        );
    }

    return (
        <div
            className={`${sx} ${iconExist && styles.checkIcon} ${styles.container} ${disabled && styles.disabled}`}
            onClick={disabled ? undefined : onClick}
        >
            {iconExist && <CheckIcon/>}
            <div className={styles.btn}>
                {text}
            </div>
        </div>
    );
};

export default CustomButton;