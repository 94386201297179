export const routes = {
    dashboard: '/',
    allCards: '/all-cards',
    createOrder: '/create-order'
};

export const navigateTo = {
    dashboard: routes.dashboard,
    allCards: routes.allCards,
    createOrder: routes.createOrder
};